<template>
  <div>
    <el-select

      v-model="position"
      style="width:180px;margin-bottom: 8px;margin-right: 8px;"
      :value="result"
      placeholder="请输入定位地址"
      :remote-method="handlePOI"
      filterable
      remote
      @change="handlePos"
    >
      <el-option v-for="(result, idx) in results" :key="idx"
                 :label="result.name" :value="idx">
        {{ result.name }}
      </el-option>
    </el-select>
    <el-button type="text" @click="handleShow"><i class="el-icon-zoom-in"></i>放大</el-button>
    <el-button type="text" @click="height<120? height:height -= 20"><i class="el-icon-zoom-out"></i>缩小</el-button>
    <span  v-show="pos.lat" style="margin-left: 8px;font-size: 12px">已选地理位置:{{pos.province}}{{pos.city}}{{pos.address}}</span>
    <div class="map-container" :id="id" :style="{height: height +'px'}"></div>
  </div>
</template>

<script>
  /* eslint-disable */
  import {TextToCode} from './app'
  export default {
    name: 'map-container',
    components: {},
    mounted: function () {

    },
    data: function () {
      return {
        id: '',
        search: null,
        addr: '',
        position: '',
        results: [],
        result: undefined,
        map: null,
        pos: {
          province: '',
          city: '',
          address: '',
          lng: '',
          lat: ''
        },
        height: 240,
        zoom: 11,
      }
    },
    methods: {
      handleShow() {
        this.height += (this.height > 560 ? 0 :20)
        if (this.pos.lng) {
          let point = new BMap.Point(this.pos.lng, this.pos.lat);
          this.zoom = this.zoom > 16 ? this.zoom : this.zoom + 1
          this.map.centerAndZoom(point, this.zoom);
        }
      },
      handleSelectCity(value) {
        if (value.length === 1) {
          this.pos.province = value[0]
          this.pos.city = value[0]
        } else {
          this.pos.province = value[0]
          this.pos.city = value[1]
        }
      },
      searchResult(results) {
        if (results.length > 0) {
          results = results[0]
        }
        results = results.Br || []
        this.results = []
        for (let i = 0; i < results.length; i++) {
          let result = results[i]
          if (result.province) {
            this.results.push({
              name: result.title,
              province: result.province,
              city: result.city,
              address: result.address,
              lat: result.point.lat,
              lng: result.point.lng,
            })
          }
        }
        this.map.clearOverlays()
        this.map.removeOverlay()
      },

      getPos() {
        if (TextToCode[this.pos.province] && TextToCode[this.pos.province][this.pos.city]) {
          this.pos.cityCode = TextToCode[this.pos.province][this.pos.city].code
        }
        return this.pos
      },
      handlePos(value) {
        this.result = value
        this.pos.province = this.results[value].province
        this.pos.city = this.results[value].city
        this.pos.address = this.results[value].address
        this.pos.lat = this.results[value].lat
        this.pos.lng = this.results[value].lng
        this.map.clearOverlays()
        this.map.removeOverlay()
        let pt = new BMap.Point(this.pos.lng, this.pos.lat);
        let mk = new BMap.Marker(pt)
        this.map.addOverlay(mk);
        this.map.centerAndZoom(pt, 13);
      },

      handlePOI(value) {
        if (value) {
          this.search.search([value])
        }

      },
      initMap(pos) {
        let vue = this
        vue.pos.province = pos.province || ''
        vue.pos.city = pos.city || ''
        vue.pos.address = pos.address || ''
        vue.pos.lng = pos.lng || ''
        vue.pos.lat = pos.lat || ''
        this.id = "map-" + new Date().Format('yyyyMMddhhmmss')
        setTimeout(function () {
          var mk = null
          // let BMap = window.BMap
          vue.map = new BMap.Map(vue.id, {enableMapClick: false});
          let map = vue.map
          if (vue.pos.lat !== '') {
            let lat = parseFloat(vue.pos.lat)
            let lng = parseFloat(vue.pos.lng)
            var pt = new BMap.Point(lng, lat);
            mk = new BMap.Marker(pt)
            map.addOverlay(mk);
            map.centerAndZoom(pt, 16);
          } else {
            var point = new BMap.Point(116.404, 39.915);
            map.centerAndZoom(point, 11);
            new BMap.LocalCity().get(function (result) {
              map.setCenter(result.name);
            });
          }
          map.enableScrollWheelZoom(true);
          map.addControl(new BMap.ScaleControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT}));
          map.addControl(new BMap.NavigationControl());

          vue.search = new BMap.LocalSearch(map, {
            renderOptions: {map: map},
            onSearchComplete: vue.searchResult
          });

          var myGeo = new BMap.Geocoder();

          map.addEventListener("click", function (e) {
            var pt = e.point;
            myGeo.getLocation(pt, function (rs) {
              vue.pos.province = rs.addressComponents.province
              vue.pos.city = rs.addressComponents.city
              vue.pos.address = rs.addressComponents.district + rs.addressComponents.street
              vue.pos.lat = rs.point.lat
              vue.pos.lng = rs.point.lng
              vue.map.clearOverlays()
              if (mk !== null) {
                map.removeOverlay(mk)
              }
              mk = new BMap.Marker(pt)
              map.addOverlay(mk);

            })
          })
        }, 1500)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .BMap_cpyCtrl {
    display: none;
  }

  ::v-deep .anchorBL {
    display: none;
  }

  .map-container {
    min-width: 480px;
  }
</style>
